import React from "react"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import Logo from "../images/logo.png"
import SEO from "../components/seo"
import CTA from "../components/cta"
import Background1 from "../images/bg-12.jpg"

const IndexPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title="Home" />
      <div className="h-100 pb-4">
        <div
          className="w-full text-center text-gray-100 bg-center object-center"
          style={{ backgroundImage: `url(` + Background1 + `)` }}
        >
          <div className="bg-gray-200 bg-opacity-50 w-full h-full py-56">
            <span className="bg-red-700 text-white font-bold text-3xl py-5 px-10 rounded">
              <FormattedMessage id="since" />
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 text-gray-700">
          <div className="col-span-1 text-center py-10 px-10">
            <h2 className="text-2xl font-bold py-5">
              {" "}
              <FormattedMessage id="yarn_trade_title" />
            </h2>
            <p className="text-sm mb-5">
              <FormattedMessage id="yarn_trade_desc" />
            </p>
            <Link
              to={intl.formatMessage({ id: "about_iplik_url" })}
              className="text-red-700 hover:bg-red-700 hover:text-white py-2 px-5 rounded mt-10"
            >
              <FormattedMessage id="details" />
            </Link>
          </div>
          <div className="col-span-1 text-center py-10 px-10">
            <h2 className="text-2xl font-bold py-5">
              <FormattedMessage id="weaving_trade_title" />
            </h2>
            <p className="text-sm mb-5">
              <FormattedMessage id="weaving_trade_desc" />
            </p>
            <Link
              to={intl.formatMessage({ id: "about_dokuma_url" })}
              className="text-red-700 hover:bg-red-700 hover:text-white py-2 px-5 rounded"
            >
              <FormattedMessage id="details" />
            </Link>
          </div>
          <div className="col-span-1 text-center py-10 px-10">
            <h2 className="text-2xl font-bold py-5">
              <FormattedMessage id="product_title" />
            </h2>
            <p className="text-sm mb-5">
              <FormattedMessage id="product_desc" />
            </p>
            <Link
              to={intl.formatMessage({ id: "products_url" })}
              className="text-red-700 hover:bg-red-700 hover:text-white py-2 px-5 rounded"
            >
              <FormattedMessage id="details" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
